.scan-resultat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 80vh;
}

.valide-image{
    width:20%
}