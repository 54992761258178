.paragraphe_explicatif{
    width: 300px;
    max-width: 90%;
    text-align: center;
}

/* Bold text */
.bold {
    font-weight: bold;
}

/* Indications_container */
.indication_container {
    display: flex;
    justify-content: center;
    border:solid 3px #570CD3;
    border-radius: 5px;
    background-color: #ae8ee3;
    padding:5px;
    width:300px;
    max-width: 90%;
}

.indication_img{
    width:70%
}

.indication_img_scan{
    width:50%
}

.center-childs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Center horizontaly everything in the class home_container */
.home_container {
    padding-top: 20px;
    display: flex;
    /* justify-content: center; */
    row-gap: 20px;
    align-items: center;
    flex-direction: column;
    /* background-color: rgb(254, 242, 241); */
    min-height: 90vh;
}

.warning{
    color : #FFA500
}