/* Import Poppins, Montserrat from google fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Set all title to Poppins */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

/* Set all other texts to Montserrat */
body,
p,
a,
li,
td,
th,
button,
input,
textarea,
label,
select,
.navbar-brand {
  font-family: 'Montserrat', sans-serif;
}

/* .button-with-gradient-border {
  background-image: radial-gradient(circle at 100% 100%, transparent 15px, #580ed2 15px, #580ed2 20px, transparent 20px), linear-gradient(to right, #580ed2, #388dea), radial-gradient(circle at 0% 100%, transparent 15px, #388dea 15px, #388dea 20px, transparent 20px), linear-gradient(to bottom, #388dea, #FF6F39), radial-gradient(circle at 0% 0%, transparent 15px, #FF6F39 15px, #FF6F39 20px, transparent 20px), linear-gradient(to left, #FF6F39, #3f93ee), radial-gradient(circle at 100% 0%, transparent 15px, #3f93ee 15px, #3f93ee 20px, transparent 20px), linear-gradient(to top, #3f93ee, #580ed2);
  background-size: 20px 20px, calc(100% - 40px) 5px, 20px 20px, 5px calc(100% - 40px);
  background-position: top left, top center, top right, center right, bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat
} */

.button-with-gradient-border {
  border-radius: 100rem;
  padding: 1rem;
  font-family: 'Avenir Next';
  font-size: 1rem;
  padding: .5rem 3rem;
  color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #580ed2, #FF6F39);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  font-weight: bold;
}

/* Center logo in the div */
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

/* error */
.error {
  color: red;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

.legende{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color:grey;
  font-size: 14px;
  max-width: 90%;
  width:300px;
}

.divider{
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin-top: 20px;
  margin-bottom: 20px;
}